import { FC } from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { LandingPage } from './Components';

const routes = [
  {
    path: '*',
    Component: LandingPage,
  },
];

export const AppRoutes: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        {routes.map(({ path, Component }) => {
          return <Route key={path} path={path} element={<Component />} />;
        })}
      </Routes>
    </BrowserRouter>
  );
};
