import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
  //   palette: {
  //     primary: {
  //       main: '#7a6c6c', // Change the primary color
  //     },
  //     secondary: {
  //       main: '#ff6f61', // Change the secondary color
  //     },
  //   },
  typography: {
    fontFamily: 'Visby',
    fontSize: 16,
  },
});

theme = responsiveFontSizes(theme);

export { theme };
