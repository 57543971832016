import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      color: '#FFF',
      padding: 8,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      maxWidth: '100vw',
      background: 'linear-gradient(0deg, #000 0%, #000 100%), #FFF;',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },

    title: {
      textAlign: 'center',
      fontFeatureSettings: "'ss02' on",
      fontWeight: 500,
      lineHeight: '100%',
      letterSpacing: -3.44,
      fontSize: 86,
      [theme.breakpoints.down('lg')]: {
        fontSize: 60,
      },
    },

    form: {
      width: '80%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    formControl: {
      width: '100%',
      fontFamily: 'Visby !important',
    },

    emailInput: {
      height: 80,
      borderRadius: 10,
      paddingLeft: '20px',
      letterSpacing: -0.8,
      background: '#FFF',
    },

    notifyBtn: {
      height: '100%',
      padding: '28px 40px !important',
      textWrap: 'nowrap',
      borderRadius: '0 10px 10px 0 !important',
      background: 'linear-gradient(106deg, #52A8DD 0%, #7FFAC9 110.8%)',
    },

    notifyBtnText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    helperText: {
      color: '#FFF',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 4,
      fontFeatureSettings: "'ss02' on",
    },
  };
});
