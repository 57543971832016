import * as React from 'react';
import { AppLogo } from '../../Images/AppLogo';
import {
  Button,
  CircularProgress,
  FormControl,
  Input,
  Typography,
} from '@mui/material';
import { useStyles } from './LandingPage.styles';
import { useState } from 'react';
import axios from 'axios';

export const LandingPage = () => {
  axios.defaults.baseURL = process.env.REACT_APP_API;

  const [data, setData] = useState<{
    email: string;
    status: 'initial' | 'loading' | 'error' | 'success';
    error?: string;
  }>({
    email: '',
    status: 'initial',
  });

  const isLoading = data.status === 'loading';
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({ email: event.target.value, status: 'initial' });
  };

  const isValidEmail = (email: string) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setData((current) => ({ ...current, status: 'loading' }));
    if (isValidEmail(data.email)) {
      axios
        .post('/notify/recipients', {
          email: data.email,
          type: 'upcoming',
        })
        .then((response) => {
          setData((current) => ({ ...current, status: 'success' }));
        })
        .catch((error) => {
          setData((current) => ({
            ...current,
            status: 'error',
            error: 'An error occurred, please retry again',
          }));
        });
    } else {
      // not valid email
      setData((current) => ({
        ...current,
        status: 'error',
        error: 'Please enter a valid email address',
      }));
    }
  };

  const {
    container,
    wrapper,
    notifyBtn,
    title,
    emailInput,
    helperText,
    form,
    formControl,
    notifyBtnText,
  } = useStyles();

  return (
    <div className={container}>
      <AppLogo />
      <div className={wrapper}>
        <h1 className={title}>
          We're building
          <br />
          something inspiring
        </h1>
        <form className={form} onSubmit={handleSubmit} id="waitingList">
          <FormControl className={formControl}>
            {data.status !== 'success' && (
              <Input
                placeholder="youremail@example.com"
                type="email"
                className={emailInput}
                required
                value={data.email}
                disabled={isLoading}
                onChange={handleChange}
                error={data.status === 'error'}
                sx={{
                  color: '#15222A',
                  fontWeight: 500,
                  fontSize: 20,
                  lineHeight: '100%',
                }}
                endAdornment={
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isLoading}
                    className={notifyBtn}
                    style={{ textTransform: 'none', fontSize: 20 }}
                  >
                    <span className={notifyBtnText}>
                      Notify me
                      {isLoading && (
                        <CircularProgress
                          disableShrink
                          size={15}
                          color="success"
                        />
                      )}
                    </span>
                  </Button>
                }
              />
            )}
            {data.status === 'initial' && (
              <Typography
                variant="subtitle1"
                className={helperText}
                sx={{
                  fontWeight: 500,
                  lineHeight: '100%',
                  fontSize: 20,
                }}
              >
                Leave your email to get notified when we launch
              </Typography>
            )}
            {data.status === 'error' && (
              <Typography
                color="#FF0000"
                variant="subtitle1"
                className={helperText}
              >
                {data.error || 'Oops! something went wrong, please try again.'}
              </Typography>
            )}
            {data.status === 'success' && (
              <Typography variant="subtitle1" className={helperText}>
                Thank you for sign up, we'll keep you posted with our updates!
              </Typography>
            )}
          </FormControl>
        </form>
      </div>
    </div>
  );
};
